import { SteppedConsumptionRate } from '@/services/typed-graphql-sdk';

export const isGreenaRE100ProductCode = (productCode: string): boolean =>
	/^GREENA-RE-?100(-|$)/.test(productCode);

export const EV_TOU_SCHEME = 'tgoe_ev_tou_scheme';

export const SOLAR_TOU_SCHEME = 'tgoe_solar_tou_scheme';

export const ALL_ELECTRIC_TOU_SCHEME = 'tgoe_all_denka_tou_scheme';

export const GREENA_CHUBU_TOU_SCHEME = 'greena_chubu_tou_scheme';

export const GREENA_TOKYO_TOU_SCHEME = 'greena_tokyo_tou_scheme';

export const GREENA_KANSAI_TOU_SCHEME = 'greena_kansai_tou_scheme';

/**
 * @krakenTodo
 * Return the consumption charge type and translation.
 *
 * @note
 * There are two consumption charges for the time-of-use products.
 * One is the special nightly consumption charge
 * One is the special daily consumption charge
 *
 * We know the nightly consumption charge will be the cheaper of the two
 *
 * Problem: the API does not qualify which is which
 * Solution: take the cheaper consumption charge as nighttime charge to display
 *
 * @returns [NightTimeSteppedConsumptionRate, DayTimeSteppedConsumptionRate]
 */
export const sortDayNightTimeOfUseCharges = (
	consumptionCharges: SteppedConsumptionRate[]
): [SteppedConsumptionRate, SteppedConsumptionRate] =>
	Number(consumptionCharges[0].pricePerUnitIncTax) <
	Number(consumptionCharges[1].pricePerUnitIncTax)
		? [consumptionCharges[0], consumptionCharges[1]]
		: [consumptionCharges[1], consumptionCharges[0]];
