// TODO: Make keys more generic in case we need to update the product variant codes for future pricing tests
export const GREEN_PRODUCT_CODES = {
	DEC: 'JPN_GREEN_OCTOPUS_DEC_23',
	JUL: 'JPN_GREEN_OCTOPUS_JUL_24_DIRECT',
	SEP: 'JPN_GREEN_OCTOPUS_SEP_24',
};

const GREEN_PRODUCT_VARIANTS = [
	GREEN_PRODUCT_CODES.JUL,
	GREEN_PRODUCT_CODES.SEP,
];

// Affiliates that show should show the JUL pricing variant
const VALID_ORGANISATION_NAMES = [
	'FLC Premium(ES・New Green Octopus)',
	'In-House(ES・New Green Octopus)',
	'In-House(FS・New Green Octopus)',
	'New Port(FS・New Green Octopus)',
];

export const isPricingTestOrganisation = (affiliateOrgName: string): boolean =>
	VALID_ORGANISATION_NAMES.includes(affiliateOrgName);

export const isGreenProductVariant = (productCode: string): boolean =>
	GREEN_PRODUCT_VARIANTS.includes(productCode);
