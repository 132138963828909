import {
	GREEN_PRODUCT_CODES,
	isGreenProductVariant,
	isPricingTestOrganisation,
} from '@/domain/product/pricing-test/pricing-test';
import { useIsDirectChannel } from '@/hooks/useIsDirectChannel';
import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import {
	QuotedElectricityProduct,
	TariffSummary,
} from '@/services/typed-graphql-sdk';

export type UseGreenProductProps = {
	filterGreenProduct: (
		products: QuotedElectricityProduct[]
	) => QuotedElectricityProduct[];
	filterGreenTariff: (tariffs: TariffSummary[]) => TariffSummary[];
};

// Determine whether the product can be displayed or not.
// The focus is mainly on determining when to display the correct green product variant.
const canShowProduct = (
	productCode: string,
	isDirectChannel: boolean,
	isValidOrganisation = false
): boolean => {
	// Show the JUL product variant
	const showJULVariant =
		productCode === GREEN_PRODUCT_CODES.JUL && isValidOrganisation;

	// Show SEP product variant
	const showSEPVariant =
		productCode === GREEN_PRODUCT_CODES.SEP && isDirectChannel;

	// Show the default green product
	const showDefaultProduct =
		productCode === GREEN_PRODUCT_CODES.DEC &&
		!isDirectChannel &&
		!isValidOrganisation;

	// Show all other non green products as is
	const isGreenProduct =
		Object.values(GREEN_PRODUCT_CODES).includes(productCode);

	// Display green product JUL variant only if the organisation is valid
	return (
		showJULVariant || showSEPVariant || showDefaultProduct || !isGreenProduct
	);
};

// Determine which green product to include in a list of products.
// The product is a generic type as it can possibly be a QuotedElectricityProduct or TariffSummary.
// Since the shape of the products are different, getCode function is used to locate the productCode.
export const filterGreenProduct = <T>(
	products: T[],
	getCode: (product: T) => string,
	isDirectChannel: boolean,
	isValidOrganisation: boolean
): T[] =>
	// Only filter the products if the green product variant(s) are present
	products.some((product) => isGreenProductVariant(getCode(product)))
		? products.filter((product) =>
				canShowProduct(getCode(product), isDirectChannel, isValidOrganisation)
			)
		: products;

export const useGreenProduct = (): UseGreenProductProps => {
	const isDirectChannel = useIsDirectChannel();

	const isValidOrganisation = isPricingTestOrganisation(
		useReadSessionStorage<string | null>('affiliateOrganisationName') as string
	);
	return {
		filterGreenProduct: (products) =>
			filterGreenProduct(
				products,
				({ product }) => product.code,
				isDirectChannel,
				isValidOrganisation
			),

		filterGreenTariff: (tariffs) =>
			filterGreenProduct(
				tariffs,
				({ code }) => code,
				isDirectChannel,
				isValidOrganisation
			),
	};
};
